import { fetchDataSearchView } from '@/api/DDJ/data';
export default {
  components: {},
  data() {
    return {
      filter: {
        id: this.$route.query.id,
      },
      country: this.$route.query.country,
      os: this.$route.query.os,
      tagName: [],
      options: {},
      list: [],
      resData: {},
      loading: {
        list: false,
      },
      // pages: {
      //   pageNum: 1,
      //   pageSize: 10,
      // },
      // pagesTotal: 0,
      // isShowPage: false,
    };
  },
  mounted() {
    this.getList();
  },
  computed: {},
  methods: {
    getList() {
      this.loading.list = true;
      fetchDataSearchView(this.filter).then((res) => {
        let data = res.result;
        this.list = [];
        this.list = data.reportVOList;
        this.resData = data;
        // this.pagesTotal = data.total;
        this.loading.list = false;
        // this.isShowPage = true;
      });
    },
    // 分页监听
    handleCurrentChange(val) {
      this.pages.pageNum = val;
      this.filterFun();
    },
  },
  watch: {
    tagName(newValue, oldValue) {
      console.log(oldValue);
      console.log(newValue);
      if (newValue.length == 0) {
        this.list = this.resData.reportVOList;
      } else {
        let res = this.resData.reportVOList.filter((item) => {
          return newValue.indexOf(item.tagName) > -1;
        });
        this.list = res;
      }
    },
  },
};
